import React, { createContext, useState, useEffect } from 'react';
import { get } from 'lodash';
import useHasMounted from 'hooks/use-has-mounted';
import { useRouter } from 'next/router';
import { IframeContextProps, IframeContextType, IframeData } from './types';

const HEIGHT_INTERVAL = 1000;
const iframeContext = createContext<IframeContextType>({
  iframeData: null,
  inIframe: false,
  topEdge: 0,
  bottomEdge: 0,
  goTop: () => null
});

const IframeProvider: React.FC<IframeContextProps> = (props) => {
  const router = useRouter();
  const hasMounted = useHasMounted();
  const [initialized, setInitialized] = useState(false);
  const [iframeData, setIframeData] = useState<IframeData>(null);
  const [inIframe, setInIframe] = useState(false);
  const [topEdge, setTopEdge] = useState(0);
  const [bottomEdge, setBottomEdge] = useState(0);

  /* -------------------------------------------------------------------------- IFRAME METHODS--- */

  const reportHeight = (prev: number): number => {
    const height = document.getElementById('yps_car_ibe').clientHeight;
    if (prev !== height) {
      parent.postMessage(
        {
          method: 'updateHeight',
          iframeHeight: document.getElementById('yps_car_ibe').clientHeight
        },
        '*'
      );
      return height;
    }
    return prev;
  };

  const goTop = () => {
    parent.postMessage({ method: 'scrollTop' }, '*');
  };

  const reportPageChange = (url: string) => {
    if (!props.updateIframeUrl) return;
    parent.postMessage({ method: 'updateHash', url }, '*');
  };

  const reinitIframe = () => {
    parent.postMessage({ method: 'reinitIframe' }, '*');
  };

  /* ---------------------------------------------------------------------------- EMIT EVENTS --- */

  const emitPositionReport = (e: any) => {
    const top = get(e, 'data.topEdge', 0);
    const bottom = get(e, 'data.bottomEdge', 0);

    if (top !== topEdge) {
      setTopEdge(top);
    }
    if (bottom !== bottomEdge) {
      setBottomEdge(bottom);
    }
  };

  const emitSummaryReport = (e: any) => {
    setIframeData({
      url: get(e, 'data.url', ''),
      origin: get(e, 'data.origin', ''),
      pathname: get(e, 'data.pathname', ''),
      searchQuery: get(e, 'data.searchQuery', '')
    });
  };

  /* ----------------------------------------------------------------------------------- MAIN --- */

  const handleResizerMessage = (e: any) => {
    const { source, method } = get(e, 'data', {});

    if (source !== 'ypsnet-cars-resizer') return;

    switch (method) {
      case 'scrollReport':
        break;

      case 'positionReport':
        emitPositionReport(e);
        break;

      case 'summary':
        emitSummaryReport(e);
        break;

      default:
    }
  };

  useEffect(() => {
    if (!hasMounted) return () => null;

    const iframe = window.self !== window.top;
    if (inIframe !== iframe) {
      setInIframe(iframe);
    }

    if (!iframe) return () => null;

    if (!initialized) {
      document.querySelector('body').classList.add('in-iframe');
      parent.postMessage({ method: 'getSummary' }, '*');
      parent.postMessage({ method: 'getPosition' }, '*');
      setInitialized(true);
    }

    let heightInterval = null;
    let height = 0;
    window.addEventListener('message', handleResizerMessage);

    heightInterval = setInterval(() => {
      height = reportHeight(height);
    }, HEIGHT_INTERVAL);

    return () => {
      window.removeEventListener('message', handleResizerMessage);
      if (heightInterval) {
        clearInterval(heightInterval);
      }
    };
  }, [hasMounted, topEdge, bottomEdge, initialized]);

  useEffect(() => {
    const handleRouteChange = () => {
      const { pathname, search } = window.location;
      reportPageChange(`${pathname || '/'}${search || ''}`);
    };
    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return (
    <iframeContext.Provider
      value={{
        iframeData,
        inIframe,
        topEdge,
        bottomEdge,
        goTop
      }}
    >
      {props.children}
    </iframeContext.Provider>
  );
};
export { iframeContext, IframeProvider };
