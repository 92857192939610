import { simplifyStationType } from 'src/stations/station-tools';
import { cloneDeep, get, some, max } from 'lodash';
import { Order } from 'types/engine/order.type';
import { FormConfigData } from 'src/booking-engine/types';
import { Station, StationType } from 'src/stations/types';
import BrowserStorage from 'src/browser-storage';
import ExchangeOffice from 'components/common/exchange-office.class';
import nestedObjectAssign from 'nested-object-assign';
import SippCode from 'src/utils/sipp-code.class';
import {
  ArrayFilter,
  ArrayNumberFilter,
  DoorsFilter,
  Filters,
  FuelFilter,
  PassengersFilter,
  PriceFilter
} from 'src/filters/types';
import { arrayHasAllValues } from 'src/utils/array';
import { CarTerms, CarTermsRequestParams } from 'types/car-terms.type';
import { wsWarning } from 'src/logger';
import axiosInstance from 'src/axios-instance';
import { TranslateFunction } from 'providers/translations/types';
import { proxyUrl } from 'src/utils';
import { CarOffer } from './types';
import { RawOffer } from './types/raw-offer.type';
import { FuelPolicy, otherFuelPolicy } from './types/fuel-policy.type';

export const compareCarOrder = (a: CarOffer, b: CarOffer, order: Order): number => {
  switch (order.by) {
    case 'price':
      return order.order === 'asc' ? a.priceSort - b.priceSort : b.priceSort - a.priceSort;

    default:
      return 0;
  }
};

export const offerVariantHash = (item: RawOffer): string =>
  [
    simplifyStationType(get(item, 'pickupStation.locationTypes', [])),
    String(item.price),
    item.prepaid ? 'prepaid' : ''
  ].join(':');

export const countCarScoring = (car: CarOffer): number => {
  let score = 5;
  let maxScore = 7;

  const includedInsurances = car.insurances.filter((item) => item.properties.included === true);

  includedInsurances.forEach((item) => {
    if (['CDW', 'SCDW', 'LDW'].indexOf(item.key) > -1) {
      if (item.properties.included) {
        score += 1.5;
        maxScore = 8;
      }
      if (item.properties.contribution === false) {
        score += 1.5;
        maxScore = 8;
      } else if (item.properties.excessRefund === true) {
        score += 1;
        maxScore = 8;
      } else if (item.properties.contribution === true) {
        score -= 0.5;
        maxScore = 7;
      }

      if (
        some(
          {
            a: item.properties.chassis,
            b: item.properties.glass,
            c: item.properties.roof,
            d: item.properties.tires
          },
          Boolean
        )
      ) {
        score += 1;
        if (maxScore > 7) {
          maxScore = 10;
        }
      }
    }

    if (item.key === 'SCDW') {
      score -= 0.5;
    }
  });

  if (['FTF', 'PFTE', 'PFTF', 'ETE', 'FTE', 'FTF', 'HTE', 'QTE', 'SAME'].includes(car.fuelPolicy)) {
    score += 0.5;
  }

  if (car.includedKm === 0) {
    score += 0.5;
  }

  if (car.equip.includes('ac')) {
    score += 0.5;
  }

  return score > maxScore ? maxScore : score;
};

export const getCarScoreLabel = (car: CarOffer): string => {
  if (car.score > 8) return 'premium';
  if (car.score > 7) return 'economy';
  return 'basic';
};

export const createCarOffer = (
  initData: any,
  rentDays = 1,
  preloadedTerms: CarTerms = null
): CarOffer => {
  const car: any = {
    equip: [],
    addons: [],
    insurances: [],
    charges: [],
    products: [],
    variants: [],
    otherVariants: []
  };
  nestedObjectAssign(car, initData);

  if (preloadedTerms) {
    car.terms = preloadedTerms;
  }

  car.pricePerDay = initData.price / rentDays;
  const Sipp = new SippCode(initData.sipp);

  car.cartype = Sipp.cartype;
  if (car.cartype === 'truck_van') {
    car.carclass = 'commercial';
  } else if (car.cartype === 'van' && Sipp.carclass === 'mini') {
    car.carclass = 'intermediate';
  } else {
    car.carclass = Sipp.carclass;
  }
  car.fuel = Sipp.fuel;
  car.transmission = Sipp.transmission;

  if (initData.deposit > 0) {
    car.hasDeposit = true;
  } else if (initData.deposit === 0) {
    car.hasDeposit = false;
  }

  delete initData.airport_station;
  delete initData.rail_station;
  delete initData.city_station;

  if (initData.ac) car.equip.push('ac');
  if (initData.gps) car.equip.push('gps');
  if (initData.wifi) car.equip.push('wifi');
  if (initData.drive4W) car.equip.push('drive4W');
  if (initData.includedKm === 0) car.equip.push('unlimitedkm');
  if (car.fuel === 'electric' || car.fuel === 'hybrid') car.equip.push('electric');
  if (initData.prepaid) car.equip.push('prepaid');
  car.equip.push(`${car.transmission}Transmission`);
  car.score = countCarScoring(car);
  car.scoreLabel = getCarScoreLabel(car);

  if (initData.properties) {
    if (initData.properties.additionalDriver === true) car.equip.push('additionalDriver');
    if (initData.properties.noAirportFee === true) car.equip.push('hasNoAirportFee');
    if (initData.properties.noLocalFee === true) car.equip.push('noLocalFee');
    if (initData.properties.winterTires === true) car.equip.push('winterTires');
  }
  return car as CarOffer;
};

export const offerGroupToCars = (
  rawData: RawOffer,
  rentDays: number,
  stations: Array<Station>,
  jsonFeedOffersRequest = false
): Array<CarOffer> => {
  const data: RawOffer = cloneDeep(rawData);
  const results: Array<CarOffer> = [];
  const missingStations: Array<string> = [];

  const variants: Array<any> = [
    ...data.mergedIn,
    {
      offerId: data.offerId,
      pickupStation: data.pickupStation,
      dropoffStation: data.dropoffStation,
      price: data.price,
      prepaid: data.prepaid
    }
  ];

  const groups = {};
  variants.forEach((item) => {
    item.pickupStation = cloneDeep(stations.find((el) => el.code === item.pickupStation));
    item.dropoffStation = cloneDeep(stations.find((el) => el.code === item.dropoffStation));
    const key = offerVariantHash(item);
    if (groups[key] === undefined) groups[key] = [];
    groups[key].push(item);
  });
  Object.keys(groups).forEach((key) => {
    const main = groups[key].splice(0, 1)[0];
    const carVariants = cloneDeep(groups[key]);

    if (jsonFeedOffersRequest !== true) {
      if (!main.pickupStation || !main.dropoffStation) {
        console.error('missing stations data', main);
        missingStations.push(main.offerId);
        return;
      }

      variants.forEach((el) => {
        if (!el.pickupStation || !el.dropoffStation) {
          console.error('missing variant data', el, main);
          missingStations.push(el.offerId || '');
        }
      });

      if (missingStations.length > 0) {
        wsWarning('missingStationData', `No stations fata for ${missingStations.length} offers`, {
          offerIds: missingStations
        });
      }
    }

    results.push(
      createCarOffer(
        {
          ...cloneDeep(data),
          mergedIn: undefined,
          offerId: main.offerId,
          pickupStation: main.pickupStation,
          dropoffStation: main.dropoffStation,
          price: main.price,
          prepaid: main.prepaid,
          variants: carVariants,
          otherVariants: variants.filter(
            (el) =>
              el.offerId !== main.offerId &&
              el.pickupStation &&
              el.dropoffStation &&
              carVariants.findIndex((carVariant) => carVariant.offerId === el.offerId) === -1
          )
        },
        rentDays
      )
    );
  });
  return results;
};

export const carFromFormConfig = (
  data: FormConfigData,
  rentDays: number,
  agentCurrency: string,
  stations: Array<Station>,
  agentId: string
): CarOffer => {
  const oldExchangeRates = BrowserStorage.getObject(`${agentId}-exchange-office`);
  const exchangeRates = {
    agentCurrency: agentCurrency || oldExchangeRates.agentCurrency,
    rates: data.exchangeRates || {}
  };

  BrowserStorage.setObject(`${agentId}-exchange-office`, exchangeRates);
  const ExOffice = new ExchangeOffice(exchangeRates);
  const priceExchanged = ExOffice.getPriceInAgentCurrency(data.offer.price, data.offer.currency);
  const currencyExchanged = ExOffice.getAgentCurrency();
  const pricePerDayExchanged = priceExchanged / rentDays;

  if (data.offer?.addons.length > 0) {
    data.offer.addons = data.offer.addons.map((addon) => {
      if (addon.price && addon.currency) {
        return {
          ...addon,
          priceExchanged: ExOffice.getPriceInAgentCurrency(addon.price, addon.currency),
          agentCurrency
        };
      }
      return {
        ...addon
      };
    });
  }

  if (data.offer?.insurances.length > 0) {
    data.offer.insurances = data.offer.insurances.map((insurance) => {
      if (insurance.price && insurance.currency) {
        return {
          ...insurance,
          priceExchanged: ExOffice.getPriceInAgentCurrency(insurance.price, insurance.currency),
          agentCurrency
        };
      }
      return {
        ...insurance
      };
    });
  }

  return createCarOffer(
    {
      ...cloneDeep(data.offer),
      pickupStation: stations.find((el) => el.code === data.offer.pickupStation),
      dropoffStation: stations.find((el) => el.code === data.offer.dropoffStation),
      mergedIn: undefined,
      priceExchanged,
      currencyExchanged,
      pricePerDayExchanged,
      variants: data.offer.mergedIn
        .map((item) => ({
          offerId: item.offerId,
          pickupStation: stations.find((el) => el.code === item.pickupStation),
          dropoffStation: stations.find((el) => el.code === item.dropoffStation),
          price: item.price
        }))
        .filter((item) => item.pickupStation && item.dropoffStation)
    },
    rentDays
  );
};

export const meetsFuelFilter = (car: CarOffer, filter: FuelFilter): boolean => {
  if (filter.value.policy.length > 0) {
    const policies: Array<FuelPolicy> = [];
    filter.value.policy.forEach((policy) => {
      if (policy === 'other') {
        otherFuelPolicy.forEach((item) => policies.push(item));
      } else {
        policies.push(policy);
      }
    });
    if (policies.indexOf(car.fuelPolicy) === -1) return false;
  }
  return true;
};

export const carMeetsFilters = (car: CarOffer, filters: Filters): boolean => {
  if (filters.length === 0) return true;
  return filters.every((filter) => {
    switch (filter.type) {
      case 'station':
        return !!(filter as ArrayFilter<StationType>).value.filter((item) =>
          car.pickupStation.locationTypes.includes(item)
        ).length;
      case 'pickupStation':
        return (filter as ArrayFilter<string>).value.some(
          (code) =>
            code === `${car.partner}:${car.pickupStation.code}` ||
            car.variants.some((offer) => offer.pickupStation.code === code)
        );
      case 'dayPrice':
        return (
          car.pricePerDay >= (filter as PriceFilter).value.min &&
          car.pricePerDay <= (filter as PriceFilter).value.max
        );
      case 'totalPrice':
        if (car.priceExchanged > 0) {
          return (
            car.priceExchanged >= (filter as PriceFilter).value.min &&
            car.priceExchanged <= (filter as PriceFilter).value.max
          );
        }
        return (
          car.price >= (filter as PriceFilter).value.min &&
          car.price <= (filter as PriceFilter).value.max
        );
      case 'passengers':
        return car.adults >= (filter as PassengersFilter).value;
      case 'doors':
        return car.doors === (filter as DoorsFilter).value;
      case 'fuel':
        return meetsFuelFilter(car, filter as FuelFilter);
      case 'partner':
        return (filter as ArrayFilter).value.indexOf(car.partner) !== -1;
      case 'class':
        return (filter as ArrayFilter).value.indexOf(car.carclass) !== -1;
      case 'carType':
        return (filter as ArrayFilter).value.indexOf(car.cartype) !== -1;
      case 'equip':
        return arrayHasAllValues(car.equip, (filter as ArrayFilter).value);
      case 'carScore':
        return (filter as ArrayFilter).value.indexOf(car.scoreLabel) !== -1;
      case 'hasDeposit':
        if (filter.value.length < 1) return true;
        if (car.hasDeposit === true && filter.value.indexOf('has-deposit') !== -1) return true;
        if (car.hasDeposit === false && filter.value.indexOf('no-deposit') !== -1) return true;
        return false;
      case 'deposit':
        if (car.depositInEUR > 0) {
          return (
            filter.value.length < 1 ||
            (car.depositInEUR !== null &&
              car.depositInEUR <= max((filter as ArrayNumberFilter).value))
          );
        }
        return (
          filter.value.length < 1 ||
          (car.deposit !== null && car.deposit <= max((filter as ArrayNumberFilter).value))
        );
      default:
        return true;
    }
  });
};

export const getCarTerms = (
  car: CarOffer,
  data: CarTermsRequestParams,
  refresh = false,
  customProxy = ''
): Promise<CarTerms> => {
  if (car.terms && refresh !== true) return Promise.resolve(car.terms);

  return new Promise((resolve, reject) => {
    axiosInstance.post(`${proxyUrl(customProxy)}terms`, data).then((res) => {
      resolve((car.terms = res.data));
    }, reject);
  });
};

export const limitedKmLabel = (offer: CarOffer, t: TranslateFunction): string => {
  const parts = [offer.includedKm, 'km'];
  if (offer.includedKmPerUnit === 'day') {
    parts.push(t('price_day'));
  }
  return parts.join(' ');
};
